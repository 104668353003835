<template>
    <div class="pass-container">
        <h3>تغییر رمز عبور</h3>
        <div class="content">
            <div class="input-container">
                        <!-- <div class="spans">
                            <span class="input-span"> رمز عبور </span>
                        </div> -->
                        <label for="email">
                            <img src="../../../assets/icons/inputarrow.svg" alt="">
                            <input placeholder="رمز عبور فعلی" v-model="data.oldPass" :type="showOldPass ? 'text':'password'">
                            <img @click="showOldPass=true" v-if="!showOldPass" src="../../../assets/icons/dontseepassword.svg" alt="">
                            <img @click="showOldPass=false" v-if="showOldPass" class="seepass" src="../../../assets/icons/seepassword.svg" alt="">
                        </label>
            </div>
            <div class="input-container">
                        <!-- <div class="spans">
                            <span class="input-span"> رمز عبور </span>
                        </div> -->
                        <label for="email">
                            <img src="../../../assets/icons/inputarrow.svg" alt="">
                            <input placeholder="رمز عبور جدید" v-model="data.newPass" :type="showNewPass ? 'text':'password'">
                            <img @click="showNewPass=true" v-if="!showNewPass" src="../../../assets/icons/dontseepassword.svg" alt="">
                            <img @click="showNewPass=false" v-if="showNewPass" class="seepass" src="../../../assets/icons/seepassword.svg" alt="">
                        </label>
            </div>
            <button class="submit"> تغییر رمز عبور </button>
        </div>    
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data:{
                    oldPass:'',
                    newPass:'',
                },
                showOldPass:false,
                showNewPass:false,
            }
        },
    }
</script>

<style lang="scss" scoped>
.content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.pass-container{
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px;
    width: 100%;
}
.input-container,.submit{
    width: 30% !important;

}
.input-container{
    label,input{
        background: rgba(236, 238, 239, 1);
    }
}
</style>