<template>
    <div class="comp">
        <div class="comp-container">
            <SMSEmailModal v-if="SMSEmailShow" @close="SMSEmailShow=false" :type="authType" />
            <h2> تنظیمات </h2>
            <div class="content">
                <TwoStep @showModal="openModal" />
                <ChangePassword />
                <LoginHistorySetting />
            </div>
        </div>
    </div>
</template>

<script>
import LoginHistorySetting from './LoginHistorySetting.vue'
import ChangePassword from './ChangePassword.vue'
import TwoStep from './TwoStep.vue'
import SMSEmailModal from './SMSEmailModal.vue'
export default {
        name:'Notifications',
        components:{
            LoginHistorySetting,
            ChangePassword,
            TwoStep,
            SMSEmailModal
        },
        data() {
            return {
                authType:'',
                SMSEmailShow:false,
            }
        },
        methods:{
            openModal(v){
                console.log(v);
                this.authType=v;
                if(v!=='GOOGLEAUTHENTICATOR'){

                    this.SMSEmailShow=true
                }else{
                    this.showGoogleModal=true
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.content{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 70%;
}
h2{
    font-size: clamp(16px,2vw,20px);
}
.comp-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: center;
    justify-content: center;
}
.comp{
    min-height: 92vh;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
</style>