<template>
    <div class="towstep">
        <h3> شناسایی دو عاملی </h3>
        <div class="towstep-items">
            <div class="item">
                <img src="../../../assets/icons/mail.svg" alt="">
                <div class="check-container">
                    <div @click="OpenModal('EMAIL')" :class="modalType=='EMAIL'?'checked':'checkbox'" class="">
                        <div></div>
                    </div>
                    <span> ایمیل </span>
                </div>
                <span class="shadowtext"> ارسال کد به ایمیل </span>
            </div>
            <div class="item">
                <img src="../../../assets/icons/mobilesetting.svg" alt="">
                <div class="check-container">
                    <div @click="OpenModal('SMS')" :class="modalType=='SMS'?'checked':'checkbox'" class="">
                        <div></div>
                    </div>
                    <span> SMS </span>
                </div>
                <span class="shadowtext"> ارسال کد به شماره موبایل </span>
            </div>
            <div class="item">
                <img src="../../../assets/icons/googlesett.svg" alt="">
                <div class="check-container">
                    <div @click="OpenModal('GOOGLEAUTHENTICATOR')" :class="modalType=='GOOGLEAUTHENTICATOR'?'checked':'checkbox'" class="">
                        <div></div>
                    </div>
                    <span> GOOGLE AUTHENTICATOR </span>
                </div>
                <span class="shadowtext"> ارسال کد به google authenticator </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'TwoStepSetting',
        data() {
            return {
                modalType:'',
            }
        },
        methods:{
            OpenModal(v){
                this.modalType=v;
                this.$emit('showModal',this.modalType)
            }
        }
    }
</script>

<style lang="scss" scoped>
.shadowtext{
    color: var(--grey);
    font-size: clamp(12px,1vw,14px);
}
.check-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 5px;
    align-items: center;
    width: 100%;
}
.checked{
    div{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--primary-color);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--light-yellow);
    border: 1px solid var(--primary-color);
    padding: 2px;
    cursor: pointer;
}
.checkbox{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--light-grey);
    border: 1px solid var(--grey);
    padding: 2px;
    cursor: pointer;
}
.towstep-items{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 12px;
    column-gap: 24px;
    width: 100%;
    justify-content: center;
    .item{
        width: 30%;
        background: white;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 24px;
        border-radius: 12px;
        img{
            max-width:80px;
            max-height:80px;
        }
    }
}
.towstep{
    display: flex;
    padding: 24px;
    gap: 24px;
    flex-direction: column;
}
</style>