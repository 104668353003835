<template>
    <div class="enterhistory">
            <span class="title-text">
                تاریخچه ورود
            </span>
            <table>
                <tr class="thead">
                    <td> زمان </td>
                    <td> آی پی </td>
                    <td> نوع دستگاه </td>
                    <td> پلتفرم </td>
                    <td> نوع مرورگر </td>
                </tr>
                <tbody>
                <tr v-for="(item,index) in loginData" :key="index" class="tbody">
                    <td> {{$G2J(item.loginRequestedDateTime)}} </td>
                    <td> {{item.ipAddress}} </td>
                    <td> {{item.deviceType}} </td>
                    <td> {{item.osType}} </td>
                    <td> {{item.browser}} </td>
                </tr>
                </tbody>
            </table>
    </div>
</template>

<script>
    export default {
        name:'EnterHistory',
        data() {
            return {
                loginData:[
                    {
                        browser: "CHROME",
                        deviceType: "DESKTOP",
                        ipAddress: "185.172.0.174",
                        isSuccessful: true,
                        loginRequestedDateTime: "2022-05-22T13:58:07",
                        osType: "MACINTOSH",
                    },
                    {
                        browser: "CHROME",
                        deviceType: "DESKTOP",
                        ipAddress: "185.172.0.174",
                        isSuccessful: true,
                        loginRequestedDateTime: "2022-05-22T13:58:07",
                        osType: "MACINTOSH",
                    },
                    {
                        browser: "CHROME",
                        deviceType: "DESKTOP",
                        ipAddress: "185.172.0.174",
                        isSuccessful: true,
                        loginRequestedDateTime: "2022-05-22T13:58:07",
                        osType: "MACINTOSH",
                    },
                    {
                        browser: "CHROME",
                        deviceType: "DESKTOP",
                        ipAddress: "185.172.0.174",
                        isSuccessful: true,
                        loginRequestedDateTime: "2022-05-22T13:58:07",
                        osType: "MACINTOSH",
                    },
                    {
                        browser: "CHROME",
                        deviceType: "DESKTOP",
                        ipAddress: "185.172.0.174",
                        isSuccessful: true,
                        loginRequestedDateTime: "2022-05-22T13:58:07",
                        osType: "MACINTOSH",
                    },
                ],
            }
        },
    }
</script>

<style lang="scss" scoped>
.thead{
        border-bottom: 1px solid #cacacead;
        position: sticky;
        top: 0;
        background: white;
    td{
        text-align: center;
        color: var(--grey);
        height: 100%;
        min-height: 37px;
    }
}
tr{
    height: 37px;
}
tbody{
    height: 100%;
    overflow: scroll;
    td{
        text-align: center;
    }
}
table{
    border-collapse: collapse;
    width: 100%;
    height: 100%;
}
.enterhistory{
    height: 100%;
    border-radius: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    row-gap: 24px;
    background: white;
    min-height: 200px;
}
.title-text{
    color: var(--black-fee);
    word-spacing: 4px;
}
// @media only screen and(max-width:1700px){
//     .enterhistory{
//         max-height: 100px;
//         // overflow: hidden;
//     }
// }
</style>