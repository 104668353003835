<template>
    <transition name="fade">
        <div @click.self="closeModale" class="modal-backdrop" >
          <div class="modal">
            <div class="title">
                  <h2 v-if="type=='EMAIL'"> تایید ایمیل </h2>
                  <h2 v-if="type=='SMS'"> تایید موبایل </h2>
                  <img @click.self="closeModale" src="@/assets/icons/close.svg" alt="">
            </div>
            <p v-if="type=='EMAIL'">
                کد ارسال شده به
                ایمیل
                خود را وارد کنید
            </p>
            <p v-if="type=='SMS'">
                کد ارسال شده به
                موبایل
                خود را وارد کنید
            </p>
            <CodeInput
                :loading="false" 
                class="verif-code" 
            />
            <span @click="resendBtn" class="counter"> {{counter ? counter : 'ارسال مجدد کد'}} </span>
            <button disabled class="submit"> تایید </button>
          </div>
        </div>
    </transition>
</template>

<script>
import CodeInput from "vue-verification-code-input";
export default {
  name: 'SMSEmailModal',
  components:{
      CodeInput
  },
  props:['type'],
  data() {
      return {
          counter:120,
      }
  },
  methods : {
    resendBtn(){
                this.counter=120
    },
    countDownTimer() {
                if (this.counter > 0) {
                    this.timeOut = setTimeout(() => {
                        this.counter--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.$error('زمان شما به اتمام رسید', '')
                }
            },
      closeModale() {
        this.$emit('close' , false)
      },
  },
  mounted (){
                this.countDownTimer()
        }
  }
</script>

<style lang="scss" scoped>
.counter{
        align-self: start;
    }
.title{
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-direction: row;
}
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 119, 204, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
      row-gap: 32px;
      width: 100%;
    display: flex;
    flex-grow: 1;
    max-width: 500px;
    min-height: 160px;
    background: white;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 12px;
    position: relative;
    box-sizing: border-box;
    padding: 24px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
</style>